import React, {useMemo} from 'react';

import {
  CInput,
  CCol,
  CLabel,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CFormGroup,
  CFormText,
  CInvalidFeedback,
  CSelect,
  CSwitch,
  CTextarea,
} from '@coreui/react';

import CIcon from '@coreui/icons-react';
import * as _ from 'lodash';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import './formUtils.css';

export const customInput = ({field, form, ...props}) => {
  return (
    <CInputGroup className='mb-3'>
      <CInputGroupPrepend>
        <CInputGroupText>
          <CIcon name={props.icon} />
        </CInputGroupText>
      </CInputGroupPrepend>
      <CInput
        {...field}
        {...props}
        invalid={_.get(form.touched, field.name) && _.get(form.errors, field.name) && true}
      />
      <CInvalidFeedback className='help-block'>
        {_.get(form.touched, field.name) && _.get(form.errors, field.name)}
      </CInvalidFeedback>
    </CInputGroup>
  );
};

export const formInput = ({field, form, ...props}) => {
  return (
    <CFormGroup>
      <CLabel htmlFor={field.name}>{props.placeholder}</CLabel>
      <CInput {...field} {...props} />
    </CFormGroup>
  );
};

export const switchInput = ({field, form, ...props}) => {
  return (
    <CFormGroup row>
      <CCol md='4'>
        <CLabel htmlFor={props.name}>{props.caption}</CLabel>
      </CCol>
      <CCol xs='12' md='8'>
        <div>
          <CSwitch
            shape='pill'
            color='success'
            size='sm'
            labelOn='так'
            labelOff='ні'
            defaultChecked
            // checked={field.value === 1}
            // onChange={(e) => {
            //   console.log(e.target);
            //   form.setFieldValue(field.name, e.target.checked);
            // }}
            invalid={_.get(form.touched, field.name) && _.get(form.errors, field.name) && true}
            {...field}
            {...props}
          />

          {props.helpText ? <CFormText>{props.helpText}</CFormText> : <div />}

          <CInvalidFeedback className='help-block'>
            {_.get(form.touched, field.name) && _.get(form.errors, field.name)}
          </CInvalidFeedback>
        </div>
      </CCol>
    </CFormGroup>
  );
};

const colors = {
  divider: '#d8d8d8',
  error: '#d43f21',
};

export const customStyles = {
  control: (base, state) => {
    let statusColor = colors.divider;
    if (state.selectProps.error) {
      // "state.selectProps" references the component props
      statusColor = colors.error;
    }

    return {
      ...base,
      boxShadow: `0 0 0 1px ${statusColor}`,
      transition: ' 0.25s linear',
      transitionProperty: 'box-shadow',
    };
  },
};

export const MultiSelect = ({field, form, ...props}) => {
  let invalid = _.get(form.errors, field.name) && true;
  //if (invalid !== '') invalid = true;

  const getValue = useMemo(() => {
    if (props.items) {
      return props.items.filter((option) => field.value & option.value);
    } else {
      return [];
    }
  }, [props.items, field.value]);

  return (
    <CFormGroup row>
      <CCol>
        <Select
          styles={customStyles}
          options={props.items}
          name={props.name}
          id={props.name}
          {...field}
          {...props}
          error={invalid}
          value={getValue}
          onChange={(option) => {
            let newValue = 0;
            option.forEach((element) => {
              newValue += element.value;
            });
            form.setFieldValue(field.name, newValue);
          }}
          isMulti></Select>

        {props.helpText ? <CFormText>{props.helpText}</CFormText> : <div />}
        <div className='help-block'>
          {invalid && <div style={{fontSize: '12px', color: 'rgb(244, 67, 54)'}}>{_.get(form.errors, field.name)}</div>}
        </div>
        <CInvalidFeedback className='help-block'>{!invalid}</CInvalidFeedback>
      </CCol>
    </CFormGroup>
  );
};

export const cykorField = ({field, form, ...props}) => {
  return (
    <CFormGroup row>
      <CCol md='4' hidden={props.hideCaption}>
        <CLabel htmlFor={props.name}>{props.caption}</CLabel>
      </CCol>
      <CCol xs='12' md='8'>
        <CInput
          invalid={_.get(form.touched, field.name) && _.get(form.errors, field.name) && true}
          {...field}
          {...props}
        />

        {props.helpText ? <CFormText>{props.helpText}</CFormText> : <div />}

        <CInvalidFeedback className='help-block'>
          {_.get(form.touched, field.name) && _.get(form.errors, field.name)}
        </CInvalidFeedback>
      </CCol>
    </CFormGroup>
  );
};

export const cykorTextArea = ({field, form, hideCaption, ...props}) => {
  return (
    <CFormGroup row>
      <CCol md='4' hidden={hideCaption}>
        <CLabel htmlFor={props.name}>{props.caption}</CLabel>
      </CCol>
      <CCol>
        <CTextarea
          invalid={_.get(form.touched, field.name) && _.get(form.errors, field.name) && true}
          {...field}
          {...props}
        />

        {props.helpText ? <CFormText>{props.helpText}</CFormText> : <div />}

        <CInvalidFeedback className='help-block'>
          {_.get(form.touched, field.name) && _.get(form.errors, field.name)}
        </CInvalidFeedback>
      </CCol>
    </CFormGroup>
  );
};

export const cField = ({field, form, ...props}) => {
  return (
    <>
      <CInput
        className='placeholder'
        invalid={_.get(form.touched, field.name) && _.get(form.errors, field.name) && true}
        {...field}
        {...props}
      />

      {props.helpText ? <CFormText>{props.helpText}</CFormText> : <div />}
      {props?.type === 'date' && props.placeholder ? <CFormText color='muted'>{props.placeholder}</CFormText> : <></>}
      {props.description && <CFormText color='muted'>{props.description}</CFormText>}

      <CInvalidFeedback className='help-block'>
        {_.get(form.touched, field.name) && _.get(form.errors, field.name)}
      </CInvalidFeedback>
    </>
  );
};

export const cykorHeadlessField = ({field, form, ...props}) => {
  return (
    <CFormGroup row>
      <CCol>{cField({field, form, ...props})}</CCol>
    </CFormGroup>
  );
};

export const cykorDropDownField = ({field, form, ...props}) => {
  return (
    <CFormGroup row>
      <CCol md='4'>
        <CLabel htmlFor={props.name}>{props.caption}</CLabel>
      </CCol>
      <CCol xs='12' md='8'>
        <CSelect
          custom
          name={props.name}
          id={props.name}
          style={{fontWeight: 'bold'}}
          {...field}
          {...props}
          invalid={_.get(form.touched, field.name) && _.get(form.errors, field.name) && true}>
          {props.items?.map((item, i) => (
            <option value={item.key} key={i}>
              {item.value}
            </option>
          ))}
        </CSelect>

        {props.helpText ? <CFormText>{props.helpText}</CFormText> : <div />}

        <CInvalidFeedback className='help-block'>
          {_.get(form.touched, field.name) && _.get(form.errors, field.name)}
        </CInvalidFeedback>
      </CCol>
    </CFormGroup>
  );
};

export const cykorHeadlessDropDownField = ({field, form, ...props}) => {
  return (
    <CFormGroup row>
      <CCol>
        <CSelect
          custom
          name={props.name}
          id={props.name}
          style={{fontWeight: 'bold'}}
          {...field}
          {...props}
          invalid={_.get(form.touched, field.name) && _.get(form.errors, field.name) && true}>
          {props.items?.map((item, i) => (
            <option value={item.key} key={i}>
              {item.value}
            </option>
          ))}
        </CSelect>

        {props.helpText ? <CFormText>{props.helpText}</CFormText> : <div />}

        <CInvalidFeedback className='help-block'>
          {_.get(form.touched, field.name) && _.get(form.errors, field.name)}
        </CInvalidFeedback>
      </CCol>
    </CFormGroup>
  );
};

export const CykorPublisherLookupField = ({field, form, ...props}) => {
  const publishers = useSelector((state) => state.publishers.items);

  const congregations = useSelector((state) => state.congregations.items);

  const setCongregation = () => {};

  if (publishers) {
  }

  return (
    <CFormGroup>
      <CCol md='4'>
        <CLabel htmlFor={props.name}>{props.caption}</CLabel>
      </CCol>
      <CCol xs='12' md='8'>
        <Select
          name={props.name}
          id={props.name}
          noOptionsMessage={() => 'Збігів не знайдено'}
          options={congregations?.map((congregation) => ({
            value: congregation.id,
            label: congregation.name,
          }))}
          onChange={setCongregation}
        />
        <Select
          custom
          name={props.name}
          id={props.name}
          {...field}
          {...props}
          invalid={_.get(form.touched, field.name) && _.get(form.errors, field.name) && true}>
          {props.items?.map((item, i) => (
            <option value={item.key} key={i}>
              {item.value}
            </option>
          ))}
        </Select>

        {props.helpText ? <CFormText>{props.helpText}</CFormText> : <div />}

        <CInvalidFeedback className='help-block'>
          {_.get(form.touched, field.name) && _.get(form.errors, field.name)}
        </CInvalidFeedback>
      </CCol>
    </CFormGroup>
  );
};
