export const nullIfEmpty = (value) => {
  if (value === '') return null;

  return value;
};

export const falseIfEmpty = (value) => {
  if (value === '') return false;

  return value;
};

export const isNull = (value) => {
  return value === null || value === undefined || value === 'null';
};

export const isNullOrEmpty = (value) => {
  return isNull(value) || value === '';
};

export const emptyIfNull = (value) => {
  if (isNull(value)) return '';

  return value;
};

export const falseIfNull = (value) => {
  if (isNull(value)) return false;

  return value;
};

export const emptyIfNullObject = (value) => {
  Object.keys(value).forEach(
    (k) =>
      (value[k] && typeof value[k] === 'object' && removeEmptyOrNull(value[k])) ||
      (!value[k] && value[k] !== undefined && (value[k] = ''))
  );
  return value;
};

export const nullToEmptyObject = (value) => {
  //Check for object not array and loop with for-in. If the returned value is null change to object else keep looping with recursion
  if (typeof value === 'object' && !Array.isArray(value)) {
    for (var p in value) {
      !isNull(value[p]) ? nullToEmptyObject(value[p]) : (value[p] = '');
    }
    //Check for Array and loop with forEach. If the returned value is null change to object else keep looping with recursion .
  } else if (Array.isArray(value)) {
    value.forEach(function (e, i) {
      !isNull(e) ? nullToEmptyObject(e) : (value[i] = '');
    });
  }
};
