import _ from 'lodash';

export const any = (source, values) => {
  let found = false;
  for (let i = 0, j = values.length; !found && i < j; i++) {
    if (_.indexOf(source, values[i]?.toLowerCase()) !== -1) {
      found = true;
    }
  }
  return found;
};

export const valueByName = (source, name) => {
  for (let i = 0; i < source?.length; i++) {
    if (source[i].name === name) return source[i].value;
  }

  return undefined;
};
