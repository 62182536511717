import axios from 'axios';
import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY} from '../../const';
import {history} from '../../utils';

const axiosConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const API = axios.create(axiosConfig);
export const API_EX = axios.create(axiosConfig);

const requestInterceptor = (config) => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
};

const requestError = (error) => {
  return Promise.reject(error);
};

const responseInterceptor = (response) => {
  return response;
};

/* istanbul ignore next */
const responseError = (error) => {
  if (error.message === 'Network Error' && !error.response) {
    return Promise.reject('Помилка мережі - спробуйте пізніше!');
  }

  const originalRequest = error.config;

  if (!error.response) return Promise.reject(error.response);

  const {status, headers, data} = error.response;
  if (status === 403 && data && data.title === 'CE-0002') {
    history.push('/error', {...data});
  }

  if (status === 401 && originalRequest.url.endsWith('refresh-token')) {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    return Promise.reject(error);
  }

  if (status === 401 && !originalRequest._retry && headers['www-authenticate']) {
    originalRequest._retry = true;
    return API.post('/auth/refresh-token', {
      accessToken: localStorage.getItem(ACCESS_TOKEN_KEY),
      refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
    }).then((resp) => {
      localStorage.setItem(ACCESS_TOKEN_KEY, resp.data.accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, resp.data.refreshToken);

      axios.defaults.headers.Authorization = `Bearer ${resp.data.accessToken}`;
      return API.request(originalRequest);
    });
  }

  return Promise.reject(error.response);
};

API.interceptors.request.use(requestInterceptor, requestError);
API.interceptors.response.use(responseInterceptor, responseError);

API_EX.interceptors.request.use(requestInterceptor, requestError);
API_EX.interceptors.response.use(responseInterceptor, responseError);
